import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentServicingShared } from '../../../../../../libs/env/src/index';
import { env as baseEnv } from '../env';

export const env: EnvironmentServicingShared = merge({}, baseEnv, {
  static: {
    partnerId: '1600',
    title: 'Base Insurance',
  },
  azureAuthority: `https://login.microsoftonline.com/b6b2f512-6fd0-42fe-9659-6c5ee67da460`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  azureRedirectEndpoint: `login/callback`,
  datadogService: 'ecp-ui-servicing-agent-admin',
});
