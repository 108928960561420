// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentServicingShared } from '../../../../../libs/env/src/index';

export const env: EnvironmentServicingShared = {
  static: {
    applicationName: 'ecp-ui-servicing-agent',
    authKey: '23bb1423-c9f1-4def-ab40-a5695ff400cf',
    baseExp: 'Servicing',
    isAgent: true,
  },
};
